import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/home";
import Gallery from "./Pages/gallery";
import Mint from "./Pages/mintGallery1";
import Mint2 from "./Pages/mintGallery2";
import Mint3 from "./Pages/mintGallery3";

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
                    <Route path='/gallery' element={<Gallery />} />
                    <Route path='/Silver' element={<Mint />} />
                    <Route path='/Gold' element={<Mint2 />} />
                    <Route path='/Platinum' element={<Mint3 />} />

				</Routes>
			</BrowserRouter>
			<div>
		

			</div>
		</div>




	)
}


export default App;
