import React, { Component } from 'react';

class Ticker extends Component {


    render() {

        return (

            <div class="ticker-wrap-1">
            <div class="ticker-wrap">
            <div class="ticker">
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
              <div class="ticker__item">ADHD⚡BROS⚡NFTs</div>
            </div>
            </div>
            </div>)
    }
}

export default Ticker;